html {
  @include media-breakpoint-only(md) {
    font-size: 15px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 14px;
  }
}

h1,
h2 {
  font-weight: 700;
}

h3 {
  font-weight: 400;
}

h4,
h5,
h6 {
  font-weight: 400;
}
