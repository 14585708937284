@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounce {
  from,
  6%,
  10%,
  12%,
  to {
    transform: translateY(0);
  }

  2%,
  5% {
    transform: translateY(-30px);
  }

  9% {
    transform: translateY(-15px);
  }

  11% {
    transform: translateY(-4px);
  }
}

.bounce {
  animation: bounce 8s ease-in-out 5s infinite;

  &:hover {
    animation-play-state: paused;
  }
}

@keyframes beat {
  from,
  40%,
  to {
    transform: scale(1);
  }

  25%,
  60% {
    transform: scale(1.1);
  }
}

.beat {
  animation: beat .8s infinite;
}
